import React, { ReactChildren, ReactNode } from 'react';
import Icon from '../Icon';
import Blink from './Blink';

interface ComponentProps {
  blank?: boolean;
  avoidBlink?: boolean;
  children?: ReactChildren | ReactNode | string | JSX.Element | JSX.Element[];
  rel?: string;
  preventDefault?: boolean;
  selectArea?: boolean;
  xLink?: string;
  href?: string;
  onClick?: (event?: React.SyntheticEvent) => void;
  className?: string;
  buttonLike?: boolean;
  iconLeft?: boolean;
  iconAction?: (event?: React.SyntheticEvent) => void;
  iconClass?: string;
  iconRight?: boolean;
  aria?: string;
}

const Link = ({
  blank,
  avoidBlink,
  preventDefault,
  selectArea,
  xLink,
  onClick,
  href,
  buttonLike,
  className: classes,
  iconLeft,
  iconClass,
  iconAction,
  children,
  iconRight,
  aria,
}: ComponentProps): JSX.Element => {
  // avoidBlink para evitar que enlaces tipo emailto dejen de funcionar
  if (!blank && href && !avoidBlink) {
    if (selectArea) {
      // If recieve selectArea render new area button witho two buttons
      // First Button with area in case that have area or "Seleccione área de interés"
      // Second If exist area then button with remove icon
      return (
        <div>
          <Blink href={href}>
            <a
              onClick={onClick}
              href={href}
              target={blank ? '_blank' : '_self'}
              className={`link ${classes || ''}${buttonLike ? ' button default' : ''}`}
              rel="noopener noreferrer"
            >
              {iconLeft ? <Icon onClick={iconAction} className={iconClass} /> : null}

              {children}
            </a>
          </Blink>
          {iconRight ? (
            <a
              href={`/${xLink}`}
              onClick={iconAction}
              className="link rounded bordered cta removeButton button default"
            >
              <Icon className={iconClass} />
            </a>
          ) : null}
        </div>
      );
    }

    return (
      <Blink href={href}>
        <a
          onClick={onClick}
          href={href}
          target={blank ? '_blank' : '_self'}
          className={`link ${classes || ''} ${buttonLike ? 'button default' : ''}`}
          rel="noopener noreferrer"
          aria-label={aria || ''}
        >
          {iconLeft ? <Icon onClick={iconAction} className={iconClass} /> : null}

          {children}

          {iconRight ? <Icon onClick={iconAction} className={iconClass} /> : null}
        </a>
      </Blink>
    );
  }

  return (
    <a
      onClick={
        preventDefault
          ? (e): void => {
              e.preventDefault();
              e.stopPropagation();
              if (onClick) onClick();
            }
          : (): void => {
              if (onClick) onClick();
            }
      }
      href={href}
      target={blank ? '_blank' : '_self'}
      className={`link ${classes || ''}${buttonLike ? ' button default' : ''}`}
      rel="noopener noreferrer"
    >
      {iconLeft ? <Icon onClick={iconAction} className={iconClass} /> : null}

      {children}

      {iconRight ? <Icon onClick={iconAction} className={iconClass} /> : null}
    </a>
  );
};

export default Link;
