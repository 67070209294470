import create from 'zustand';

interface StoreSearch {
  search: string;
  lastUrl: string;
  setSearch: (search: string) => void;
  setLastUrl: (url: string) => void;
}

const useStoreSearch = create<StoreSearch>((set) => ({
  search: null,
  lastUrl: null,
  setSearch: (search: string): void => {
    set((prevState) => ({ ...prevState, search }));
  },
  setLastUrl: (lastUrl: string): void => {
    set((prevState) => ({ ...prevState, lastUrl }));
  },
}));

export default useStoreSearch;
