import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';

import useStoreSearch from '../reducer/search/storeSearch';

interface SearchHookReturn {
  search: string;
  lastUrl: string;
  setSearch: (search: string) => void;
  setLastUrl: (url: string) => void;
  isTheLastPagesSaved: () => boolean;
}

const useSearch = (): SearchHookReturn => {
  const { asPath } = useRouter();
  const [search, lastUrl, setSearch, setLastUrl] = useStoreSearch(
    (store) => [store.search, store.lastUrl, store.setSearch, store.setLastUrl],
    shallow,
  );

  const isTheLastPagesSaved = (): boolean => lastUrl === asPath;

  return {
    search,
    lastUrl,
    setSearch,
    setLastUrl,
    isTheLastPagesSaved,
  };
};

export default useSearch;
