/* eslint-disable react/button-has-type */
import React from 'react';
import Icon from '../Icon';

interface ComponentProps {
  onBlur?: (data) => void;
  onClick?: (data?) => void;
  value?: string | number;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  noStyle?: string | boolean;
  tagLike?: string | boolean;
  className?: string;
  active?: boolean;
  iconLeft?: boolean;
  title?: string;
  aria?: string;
  iconClass?: string;
  children?: string | JSX.Element | JSX.Element[] | React.ReactChildren | React.ReactNode;
  iconRight?: boolean;
}

const Button = ({
  onBlur,
  onClick,
  value,
  style,
  type = 'button',
  noStyle,
  tagLike,
  className,
  active,
  iconLeft,
  title,
  aria,
  iconClass,
  children,
  iconRight,
}: ComponentProps): JSX.Element => {
  return (
    <button
      onBlur={onBlur}
      onClick={onClick}
      value={value}
      style={style}
      type={type}
      className={`${noStyle || tagLike ? 'button' : 'button default'} ${active ? 'active' : ''} ${
        className || ''
      }${iconLeft ? ' iconLeft' : ''}${tagLike ? ' tag' : ''}`}
      title={title}
      aria-label={aria || ''}
    >
      {iconLeft && iconClass ? <Icon className={iconClass} /> : null}

      {children}

      {iconRight && iconClass ? <Icon className={iconClass} /> : null}
    </button>
  );
};

export default Button;
