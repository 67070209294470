import React from 'react';
import ReactDOMServer from 'react-dom/server';

interface ComponentProps {
  onClick?: () => void;
  nameAriaLabel?: string;
  className?: string;
  style?: Record<string, unknown>;
  toolTipData?: { id: string; content: React.ReactElement };
}

const Icon = ({
  onClick,
  className,
  nameAriaLabel,
  style,
  toolTipData,
}: ComponentProps): JSX.Element => (
  <i
    onClick={onClick || null}
    className={`icon ${className || ''}`}
    onKeyPress={onClick || null}
    role="button"
    tabIndex={0}
    aria-label={nameAriaLabel || className}
    style={style}
    data-tooltip-id={toolTipData?.id}
    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(toolTipData?.content)}
  />
);

export default Icon;
