import React, { ReactElement } from 'react';
import Link from 'next/link';

interface ComponentProps {
  children: ReactElement;
  href: string;
  prefetch?: boolean;
}

const Blink = ({ href, children }: ComponentProps): JSX.Element => {
  return (
    <Link href={href} as={href}>
      {children}
    </Link>
  );
};

export default Blink;
